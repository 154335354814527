import "../../core/src/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VWwW7bMAy95yuIAQOaYQocN8ladwWG7bLbPmDYQbZoR4ssGZLsJBvy74Nlx7Fcpc1OQSjy8ZF8orzYiftNHEXwdwaw58xuE1hG0funGUBJD6Q3NVTfEULznEskyLhVujuad4664JIIzG0CtLZqZNS82I6sFWWMy6L3DcEazpD0bh8hXs3HYT3azXG5kpYY/gcnMc6eUoPzp9lptuiasHRNYNxUgh4TyAUeWozftbE8P5JMSYvSJmAqmiFJ0e4RZetBBS8k4RZLk0CG0qIewcYO1mXcY8d/HUUTdst15ZKJlt22d4tXnTFTQulJBRYPllSal1S31ITS41LuXc4B56HDSZVmqImmjNcmAc+awLI6gFGCMz9RH3ROAZDSbFdoVUtGQsT2W27ROTqKrjeXrvhFx6Gie1qhht3cCICs1qb1rRSfzGPletPLM1XWqjKB5abNOvisnc/VdAYzJZmf8Mz/PlRUZxzgN6HrNvhGHcALIQZlBlDQKoFeKa75xAk0Aan2mlaBwa98MslWNagdpTdm28RE0CNqMvJzwT8a1IIe516Niz5KY44aZYbEciuwS9QxOrdfKolerGmKqyNoYsIzJS9TmAequQWgV804/FN4B7S/hHGNmeWqlbMSdSmH7p+F3IvKqstEXtfZg0vnr6WcllwcQwvLUGn6Y0/jDHNaCzu+zNFiHb7OTeyGJ7jcGZIKle2+upD59QXRL9Xh/5WWji/iLYsiwGQI+DZdaA//JdIJ7vc21EODD504pg0cPB5fE4+/A6YPTXCrdXMP78QhKfVIvdxcqTu3mkrDOx1SISBaxAaQGiRcElXbV0Y0Ub1HO448ZaY/GbW0bbCglUH2/M7qGt/9ulDIlS4T0MpSi3ePEcNi/lZq/8Z+KZFxCpXm0jrYxfhjZHQLu+0AcGqD2meYcokaGo77SmkLd26Twudn2Kyi6jAPgQ0ijoZn9TQ7/QNxzTux/wgAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = 'kl3620b';
export var container = 'kl36200';
export var link = 'kl36206';
export var linkPreview = 'kl36208';
export var linkPreviewContainer = 'kl36207';
export var linkPreviewRenderer = 'kl3620a';
export var linksContainer = 'kl36204';
export var linksTitles = 'kl36205';
export var notFound = 'kl36209';
export var showButton = 'kl36203';
export var title = 'kl36202';
export var titleLine = 'kl36201';