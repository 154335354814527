import "../../core/src/components/audio-player/audio-player.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/audio-player/audio-player.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WTW/bMAy951foMmA5KLDdpM3U23barxgUi7bZKpIhyfnY0P8+WP5U4nVJGvRkWKLIx0fqUYtfT5tq/bKLyJ8ZIQJtKfmRkUzC4XlG/JcKNJA61IqRVMtqq+qdjTYCDCNxeSBWSxRkx81XSnmWoQK6S6jkRzAUlUUB3701bQ7Nh/PUcIGVZeSx9PFKLgSqnJE4aRbSylhtGBGQ8Uq6emmPwhWMxFH0xTvi6WtudKUETbWsbaeBjOxKg1tujh5Hzssu2tts0bIRT7PBJeaKooOtbRapddx4UJlWju4B88Ixsoqifs3ib2AkbvP7B0IHBxeg0jswmdR7RgoUAjzj3mjYACmxtGg9JQU6oLbkKTCi9N7wsl6WtfuiBZUsGwiTCSfXlt97aT3WVozE07iHGA8XkJqCcmD6AOsQ5fLDHlbew33Y3XKTo6KmoXfdcjHUPChys76dn/XKY90rA8BHD7BndNh4uir3d2Ec7Px/vWgh1Uo03ThgWHsMXUMtoybl9j52v5dCfKmsw+xIU60cKDdsDfG+fUq8My1a3SwsY9ouvexDuvzG9u5b0enzjt9c5fQ9ks7ocIYrW3IDyl2jbd3kUFrBBPvLk0nQ/m9R0bbySZt1r2zndy9ehuOj1Njl4VFjo2dcShItEkuAWwhYY0WtBZ67D/bB2KlAyzcSRKNCJU/RHRmJFqsxVqUd5VLqPYjx6dQfOhl+HQUPyXWXYUobxXsBunl8AxkOjMOw+H2xW69TgjxRulJ3hTMgucNdUDMI1OLy5wGmWtEMJfxMtfpR21hqQMxPm8UzQ6JFbP1g5YFUZY1u31F2g5ZfntQqY5JbR3VG3bFsEvcOfLkZ8TNpbJ97kyn+bp76d34Anl779ae8/waGiosLeJ/XQy9eUVhZvJde+1o1j77B+8u9vIevyiHA60mj8Y3VsnK+0cKX0jCsRoD6mdAX6+qp+7G+HF2ZdgSEmfVIN1Knr8+zt79gOFSnQQ0AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var audioIcon = '_7bu8jv8';
export var button = '_7bu8jvb';
export var controlButton = '_7bu8jv9';
export var controls = '_7bu8jva';
export var miniCloseButton = '_7bu8jvk';
export var miniNameLabel = '_7bu8jvh';
export var miniPlayerContainer = '_7bu8jvi';
export var miniProgressContainer = '_7bu8jvj';
export var miniRoot = '_7bu8jvg';
export var nameLabel = '_7bu8jv5';
export var progressBar = '_7bu8jvd';
export var progressContainer = '_7bu8jvc';
export var progressFill = '_7bu8jve';
export var root = '_7bu8jv0';
export var sizeInfo = '_7bu8jv7';
export var spacer = '_7bu8jv6';
export var timeDisplay = '_7bu8jvf';
export var upper = '_7bu8jv1';
export var upperLeft = '_7bu8jv2';
export var upperRight = '_7bu8jv3';
export var upperRow = '_7bu8jv4';