import "../../core/src/components/notification/list.style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/notification/list.style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61TTW/bMAy951cIPTUHBY6bNqmKXfoHdthx2IGWFJuoIhqS7Ngb+t8HS4YTr+mafZwsi4/vUeTjCre+7PKM/VgwdoCOVxrLKgiWb/O6e1owdkQVKsHuHrLh/3WxShnrmKHQ1wZ6wfZGR/Tw5QqdlgHJCibJNAc7REqoBdvNOPLIIcmQE6wFd8s57PdoNW9zHnQXeG1A6oqM0m4Z6ckG7vG7Fmy9SfWZAT9VPRZdg1JoS8E2dcfymejd70R9gNB4rp2j/ya4ua5Tjo7DdUFOaccdKGx8pIv05HGEaQMBWz0T3SXUhRZvREWtdsJSuP2qIABX6KEwWn26Ca7RN9+WsbwC5EvpqLGKv9MaA712/AwXiT+32hnol2eS93/ljPGh1/V70nr40EFeS7IKXP9mnPkl+rnJt/9k0IsC8/p3UeA0XSg8mSbE6bqUsc4STUEh0GGa9biW+RidP+CaeRZNCGQ5SrLP8fiFDKrlyYOCZav7umN+uH/HDmg9Kv2cPJvSRoaO+woUHX8RHlVTLEKpBomhFyx7a1s2a9OEXJ8hH2Motv049qAgo54+mpvDQzLFiSntB1qQw4JNC/KHHnj9CeZKdTxTBQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var containerScrollViewport = 'i7sgx20';
export var error = 'i7sgx23';
export var itemContainer = 'i7sgx24';
export var itemDate = 'i7sgx26';
export var itemDeleteButton = 'i7sgx28';
export var itemList = 'i7sgx21';
export var itemMain = 'i7sgx25';
export var itemNameLabel = 'i7sgx29';
export var itemNotSupported = 'i7sgx27';
export var listEmpty = 'i7sgx22';